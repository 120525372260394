import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {first, mergeMap, Observable, of} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {selectToken} from '../store/auth/auth.selectors';
import {PlatformService} from '../services/plateform.service';
import {CookieService} from '../services/cookie.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(
    private store: Store,
    private cookieService: CookieService,
    private platformService: PlatformService
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.platformService.isPlatformServer()) {
      //console.log('[FRONT SERVER -> BACKEND] URI ' + request.url)
      let token = this.cookieService.getSpecificKeyFromCookie('access_token');
      let requestCloned = this.addAuthorizationBearerToRequest(request, token);
      return next.handle(requestCloned);
    }
    return this.addTokenFromBrowserRequest(request).pipe(
      first(),
      mergeMap((requestCloned: HttpRequest<any>) => next.handle(requestCloned))
    );
  }

  private addTokenFromBrowserRequest(request: HttpRequest<any>): Observable<HttpRequest<any>> {
    //console.log('[FRONT BROWSER -> BACKEND] URI ' + request.url);
    return this.store.pipe(
      select(selectToken),
      first(),
      mergeMap((token: string) => {
        let requestCloned = this.addAuthorizationBearerToRequest(request, token);
        return of(requestCloned);
      })
    );
  }

  private addAuthorizationBearerToRequest(request: HttpRequest<any>, token: string) {
    if (token) {
      request = request.clone({
        headers: request.headers.set('Authorization', `Bearer ${token}`),
        withCredentials: true,
      })
    } else {
      console.log(`No token "${token}" for this request ${request.url}.`);
    }
    return request;
  }
}
