import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { PlatformService } from '../services/plateform.service';

@Injectable()
export class BaseUrlInterceptor implements HttpInterceptor {
    constructor(private platformeService: PlatformService) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (request.url.includes("/assets/i18n")) { 
            request = request.clone({
                headers: request.headers.set('Cache-Control', 'no-store, no-cache'),
            });
            
            return next.handle(request);
        }
        let baseUrl = this.platformeService.isPlatformBrowser() ?  environment.baseUrl :  environment.baseUrlSsr;
        //let baseUrl =  environment.baseUrl;
        const rewriteUrl = request.clone({
            url: baseUrl + '/api/' + request.url,
        });
        
        return next.handle(rewriteUrl);
    }
}
