import jwtDecode from "jwt-decode";
import { DOCUMENT } from "@angular/common";
import { PlatformService } from "./plateform.service";
import { SsrCookieService } from "ngx-cookie-service-ssr";
import { LocalStorageService } from "./local-storage.service";
import { Inject, Injectable, InjectionToken, Optional } from "@angular/core";
import {REQUEST} from "../../express.tokens";
import { REQUEST as SSR_REQUEST } from "ngx-cookie-service-ssr";

export const APP_SSR_COOKIES = new InjectionToken<any>('APP_SSR_COOKIES');

@Injectable({
  providedIn: 'root',
})
export class CookieService {
  _cookie: Record<string, any>;
  _access_token: string;
  _jwt: Record<string, any>;

  constructor(
    private platformService: PlatformService,
    private ssrCookieService: SsrCookieService,
    private localStorageService: LocalStorageService,
    @Optional() @Inject(DOCUMENT) private doc: Document,
    @Optional() @Inject(REQUEST) private request: Request,
    @Optional() @Inject(SSR_REQUEST) private ssrRequest: Request,
    @Optional() @Inject(APP_SSR_COOKIES) private ssrCookies: string){
      this._cookie = this.getCookie();
      this._access_token = this.getSpecificKeyFromCookie('access_token');
      this._jwt = this.decodeAccessToken(this._access_token);
    }

    get cookie() {
      return this._cookie;
    }

    get access_token() {
      return this._access_token;
    }

    get jwt(){
      return this._jwt;
    }

  setCookieConsent(value: 'granted' | 'denied'){
    this.ssrCookieService.set('cookie_consent', value);
    if(this.platformService.isPlatformBrowser()){
      this.localStorageService.setToken(value);
    }
  }
    
  setAccessToken(value: string){
    this.ssrCookieService.set('access_token', value);
    if(this.platformService.isPlatformBrowser()){
      this.localStorageService.setToken(value);
    }
  }

  deleteAccessToken(){
    this.ssrCookieService.delete('access_token');
    if(this.platformService.isPlatformBrowser()){
      this.localStorageService.deleteToken();
    }
  }

  getSpecificKeyFromCookie(key: string): string {
    let cookie = this.getCookie();
    if(!cookie){
      return null;
    }
    return key in cookie ? cookie[key] : null
  }

  private getCookie(): Record<string, any>  {
    let cookieStr = this.platformService.isPlatformBrowser() ? this.doc.cookie : this.ssrCookies;
    return this.parseCookieString(cookieStr);
  }

  private parseCookieString(cookieString: string): Record<string, any> {
    if(!cookieString){
      return null;
    }
    // Split the string into individual key-value pairs
    const cookiePairs = cookieString.split('; ');
    const cookieObject: Record<string, any> = {};

    cookiePairs.forEach(pair => {
      const [key, value] = pair.split('=');

      try {
        cookieObject[key] = JSON.parse(value);
      } catch (e) {
        cookieObject[key] = value;
      }
    });

    return cookieObject;
  }

  private decodeAccessToken(access_token){
    if(!access_token){
      return null;
    }
    return jwtDecode(access_token);
  }

  getUser(): any | null {
    if(this._jwt){
      if(this.platformService.isPlatformBrowser()){
        return this.localStorageService.getUser();
      } else {
        return {
          userId: this._jwt.sub,
          role: this._jwt.auth,
          token: this._access_token
        };
      }
    }
    return null
  }
}
